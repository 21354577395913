import {constructServerSideProps} from 'utils/ssr';
import {Page} from '@halp/ui';
import {useUser, withUserProtectedRoute} from '@halp/foundation/Users';
import {CoachDomesticStudentsTableContainer} from '@halp/foundation/Students';

function CoachIndex() {
	const {user} = useUser();

	if (!user?.coachProfile) {
		return null;
	}

	return (
		<Page>
			<CoachDomesticStudentsTableContainer
				prefix="/coaching-domestic/all"
				coachId={user?.coachProfile?.id}
			/>
		</Page>
	);
}

export default withUserProtectedRoute(CoachIndex);
export const getServerSideProps = constructServerSideProps({
	dictionaries: ['coach', 'interactions'],
});
