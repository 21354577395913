import {Heading, TabMenu} from '@halp/ui';
import type {CoachingDomesticFunnelStages} from '@halp/util/constants';
import {COACHING_DOMESTIC_FUNNEL_STAGES} from '@halp/util/constants';
import {useUserType} from '../../Users';
import {Paths} from '../../Paths';
import {useI18n} from '../../i18n';
import {StudentsTable} from './StudentsTable';

import type {StudentsTableColumnsType} from './StudentsTableColumns';
import type {UserProfilePathPrefix} from '../../Paths/user';
import {useQuery} from '@halp/api/graphql';
import {CoachStudentsByFunnelStageDocument} from './CoachStudentsByFunnelStage.query';
import {snakeToCamel} from '@halp/util/string';
import {FunnelStageCount} from '../FunnelStageCount/FunnelStageCount';

interface StudentsCountPerDomesticFunnelStage {
	preConsult: number;
	consult: number;
	decision: number;
	handOff: number;
}
type CamelFunnelStages = keyof StudentsCountPerDomesticFunnelStage;

type StudentStatus = CoachingDomesticFunnelStages | 'ARCHIVED' | 'ALL';

function buildTableColumns(
	studentStatus?: StudentStatus,
): StudentsTableColumnsType[] {
	if (!studentStatus) {
		return [
			'name',
			'contact',
			'funnelStage',
			'studyDate',
			'latestInteractionType',
		];
	}

	if (studentStatus === 'ARCHIVED') {
		return [
			'name',
			'contact',
			'funnelStage',
			'studyDate',
			'archivalReason',
			'latestInteractionType',
		];
	}

	return ['name', 'contact', 'studyDate', 'latestInteractionType'];
}

interface Props {
	prefix: UserProfilePathPrefix;
	coachId: string;
	studentStatus?: StudentStatus;
}

export function CoachDomesticStudentsTableContainer({
	prefix,
	coachId,
	studentStatus,
}: Props) {
	const {t} = useI18n();
	const userType = useUserType();

	const {data} = useQuery(CoachStudentsByFunnelStageDocument, {
		variables: {coachId: coachId},
	});

	const studentsByDomesticFunnelStage =
		data?.coach?.studentsByDomesticFunnelStage;
	const archivedStudents = data?.coach?.studentsByArchiveState.archived ?? 0;

	const totalStudentsCount = studentsByDomesticFunnelStage
		? Object.values(studentsByDomesticFunnelStage).reduce<number>((a, b) => {
				if (typeof a === 'number' && typeof b === 'number') {
					return a + b;
				}
				return a;
			}, 0)
		: 0;

	const paths: Record<StudentStatus, string> = {
		ALL: Paths.users.coaching.domestic.all.url(coachId, userType),
		PRE_CONSULT: Paths.users.coaching.domestic.preConsult.url(
			coachId,
			userType,
		),
		CONSULT: Paths.users.coaching.domestic.consult.url(coachId, userType),
		DECISION: Paths.users.coaching.domestic.decision.url(coachId, userType),
		HAND_OFF: Paths.users.coaching.domestic.handOff.url(coachId, userType),
		ARCHIVED: Paths.users.coaching.domestic.archived.url(coachId, userType),
	};

	const funnelStageTabs = COACHING_DOMESTIC_FUNNEL_STAGES.map((stage) => {
		const funnelStageCamel = snakeToCamel(stage) as CamelFunnelStages;
		const count = funnelStageCamel
			? studentsByDomesticFunnelStage?.[funnelStageCamel]
			: 0;
		return {
			text: t(`coaching.tabs.${stage.toLowerCase()}`),
			to: paths[stage],
			children: count !== undefined && <FunnelStageCount count={count} />,
		};
	});

	const tabs = [
		{
			text: t('coaching.tabs.all'),
			to: paths.ALL,
			children: <FunnelStageCount count={totalStudentsCount} />,
		},
		...funnelStageTabs,
		{
			text: t('coaching.tabs.archived'),
			to: paths.ARCHIVED,
			children: <FunnelStageCount count={archivedStudents} />,
		},
	];

	return (
		<>
			<Heading type="h2" spacing="xl">
				{t('coaching.title')}
			</Heading>
			<TabMenu tabStyle="underline" tabs={tabs} />
			<StudentsTable
				prefix={prefix}
				coachId={coachId}
				columns={buildTableColumns(studentStatus)}
				filters={['latestInteractionType', 'citizenship']}
				sortColumn="insertedAt"
				domesticStudentStatus={studentStatus}
				initialStudentTypes={['DOMESTIC']}
			/>
		</>
	);
}
